<template>
    <b-row>
        <b-col cols="12">
            <b-card title="">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="ml-1 mb-2">
                        <b-row>
                            <b-col cols="12" md="9"
                                   class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            >
                                <button class="btn btn-primary" @click="exportToExcel">Exportar a Excel</button>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table
                        ref="refUserListTable"
                        class="position-relative table-size-list"
                        :items="masters_installations"
                        responsive
                        striped
                        sticky-header
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="No hay registros"
                    >

                        <template #cell(client_cif)="data">
                            {{ data.item.client_cif }}
                        </template>

                        <template #cell(installation_code)="data">
                            {{ data.item.installation_code }}
                        </template>

                        <template #cell(active)="data">
                            <b-badge :variant="data.item.active === 1 ? 'success' : 'danger'">
                                {{ data.item.active === 1 ? 'Si' : 'No' }}
                            </b-badge>
                        </template>

                        <template #cell(draft)="data">
                            <b-badge :variant="data.item.draft === 1 ? 'success' : 'danger'">
                                {{ data.item.draft === 1 ? 'Si' : 'No' }}
                            </b-badge>
                        </template>

                        <template #cell(responsible)="data">
                            {{ data.item.responsible ? data.item.responsible.name : null }}
                        </template>

                        <template #cell(figure)="data">
                            {{ figures[data.item.figure_id - 1] ? figures[data.item.figure_id - 1].name : null }}
                        </template>

                        <template #cell(date_start)="data">
                            {{ data.item.start_date ? helpers.formatDate(data.item.start_date) : null }}
                        </template>

                        <template #cell(date_end)="data">
                            {{ data.item.end_date ? helpers.formatDate(data.item.end_date) : null }}
                        </template>

                        <template #cell(period_description)="data">
                            {{ data.item.period_description }}
                        </template>

                        <template #cell(client)="data">
                            {{ data.item.client }}
                        </template>

                        <template #cell(country)="data">
                            {{ data.item.province_country ? data.item.province_country.country : null }}
                        </template>

                        <template #cell(address)="data">
                            {{ data.item.address }}
                        </template>

                        <template #cell(province)="data">
                            {{ data.item.province_country ? data.item.province_country.province : null }}
                        </template>

                        <template #cell(city)="data">
                            {{ data.item.city }}
                        </template>

                        <template #cell(installation_type)="data">
                            {{ data.item.installation_type ? data.item.installation_type.type : null }}
                        </template>

                        <template #cell(opsva_territory)="data">
                            {{ data.item.opsva_territory ? data.item.opsva_territory.name : null }}
                        </template>

                        <template #cell(client_type)="data">
                            {{ data.item.client_type ? data.item.client_type.type : null }}
                        </template>

                        <template #cell(latitud)="data">
                            {{ data.item.latitude }}
                        </template>

                        <template #cell(longitud)="data">
                            {{ data.item.longitude }}
                        </template>

                        <template #cell(client_contact_person)="data">
                            {{ data.item.client_contact_person }}
                        </template>

                        <template #cell(client_phone)="data">
                            {{ data.item.client_phone }}
                        </template>

                        <template #cell(client_email)="data">
                            {{ data.item.client_email }}
                        </template>

                        <template #cell(company)="data">
                            {{ data.item.company ? data.item.company.name : null }}
                        </template>

                        <template #cell(observations)="data">
                            {{ data.item.observations }}
                        </template>
                    </b-table>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BRow, BCol, BTable, BCard, BOverlay, BBadge} from 'bootstrap-vue'
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import ExcelJS from "exceljs"

export default {
    name: "ListView",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BOverlay,
        BBadge,
    },
    data() {
        return {
            columns: [
                {key: 'client_cif', label: 'CIF', sortable: false},
                {key: 'installation_code', label: 'Código instalación', sortable: false},
                {key: 'active', label: 'InstalaciónActiva', sortable: false},
                {key: 'draft', label: 'InstalaciónBorrador', sortable: false},
                {key: 'client', label: 'Referencia', sortable: false},
                {key: 'responsible', label: 'Responsable', sortable: false},
                {key: 'figure', label: 'Figura RD171', sortable: false},
                {key: 'country', label: 'Pais', sortable: false},
                {key: 'address', label: 'Dirección Gei', sortable: false},
                {key: 'province', label: 'Provincia/Zona', sortable: false},
                {key: 'city', label: 'Población', sortable: false},
                {key: 'installation_type', label: 'Tipo de instalación', sortable: false},
                {key: 'date_start', label: 'Fecha inicio explotación', sortable: false},
                {key: 'date_end', label: 'Fecha fin explotación', sortable: false},
                {key: 'period_description', label: 'Duración', sortable: false},
                {key: 'opsva_territory', label: 'Territorio_OPSVA', sortable: false},
                {key: 'client_type', label: 'Tipo cliente', sortable: false},
                {key: 'latitude', label: 'Coordenada X', sortable: false},
                {key: 'longitude', label: 'Coordenada Y', sortable: false},
                {key: 'client_contact_person', label: 'Contacto', sortable: false},
                {key: 'client_phone', label: 'Teléfono', sortable: false},
                {key: 'client_email', label: 'Email', sortable: false},
                {key: 'company', label: 'Empresa', sortable: false},
                {key: 'observations', label: 'Observaciones', sortable: false},
            ],
            showLoading: false,
            helpers,

            figures: [
                { 'id' : 1, 'name': 'MANTENEDOR' },
                { 'id' : 2, 'name': 'PROPIETARIO' },
                { 'id' : 3, 'name': 'TITULAR' }
            ],
        }
    },
    computed: {
        ...mapState('masterInstallation', ['masters_installations']),
    },
    created() {
        this.getInstallations()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        formatDate(date) {
            const dateFormated = new Date(date)

            return dateFormated.toLocaleDateString("es-ES")
        },
        getInstallations() {
            this.showLoading = true

            this.$store.dispatch('masterInstallation/getMasterInstallationsList', {
                relations: ['company', 'responsible', 'clientType', 'installationType', 'opsvaTerritory', 'provinceCountry'],
            })
                .then(response => {
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('InstalacionesGEI')

            const headers = [
                'CIF',
                'Código instalación',
                'InstalaciónActiva',
                'InstalaciónBorrador',
                'Referencia',
                'Responsable',
                'Figura RD171',
                'Pais',
                'Dirección Gei',
                'Provincia/Zona',
                'Población',
                'Tipo Instalación',
                'Fecha inicio explotación',
                'Fecha fin explotación',
                'Duración',
                'Territorio_OPSVA',
                'Tipo cliente',
                'Coordenada X',
                'Coordenada Y',
                'Contacto',
                'Teléfono',
                'Email',
                'Empresa',
                'Observaciones'
            ]

            worksheet.addRow(headers)

            this.masters_installations.forEach(detail => {
                worksheet.addRow([
                    detail.client_cif,
                    detail.installation_code,
                    detail.active === 1 ? 'Si' : 'No',
                    detail.draft === 1 ? 'Si' : 'No',
                    detail.client,
                    detail.responsible ? detail.responsible.name : null,
                    this.figures[detail.figure_id - 1] ? this.figures[detail.figure_id - 1].name : null,
                    detail.province_country ? detail.province_country.country : null,
                    detail.address,
                    detail.province_country ? detail.province_country.province : null,
                    detail.city,
                    detail.installation_type ? detail.installation_type.type : null,
                    detail.start_date ? helpers.formatDate(detail.start_date) : null,
                    detail.end_date ? helpers.formatDate(detail.end_date) : null,
                    detail.period_description,
                    detail.opsva_territory ? detail.opsva_territory.name : null,
                    detail.client_type ? detail.client_type.type : null,
                    detail.latitude,
                    detail.longitude,
                    detail.client_contact_person,
                    detail.client_phone,
                    detail.client_email,
                    detail.company ? detail.company.name : null,
                    detail.observations
                ])
            })

            // Generar el archivo Excel
            const buffer = await workbook.xlsx.writeBuffer()

            // Crear un Blob para la descarga
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})

            // Crear una URL para el Blob
            const url = window.URL.createObjectURL(blob)

            // Crear un enlace de descarga y hacer clic en él
            const a = document.createElement('a')
            a.href = url
            a.download = 'instalacionesGEI.xlsx'
            a.click()

            // Liberar recursos
            window.URL.revokeObjectURL(url)
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>